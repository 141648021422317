import Logo from "components/Logo/Logo";
import MenuBar from "components/MenuBar/MenuBar";
import Navigation from "components/Navigation/Navigation";
import React, { FC } from "react";
import Button from "../Button/Button";

export interface WhiteNavbarProps {}

const WhiteNavbar: FC<WhiteNavbarProps> = () => {
  return (
    <div className="nc-MainNav1 relative z-40 bg-white dark:bg-[#191C1F]/95 ">
      <div className="container">
        <div className="h-20 py-5 flex justify-between items-center">
          <div className="flex items-center lg:hidden flex-1">
            <MenuBar />
          </div>

          <div className="flex justify-center lg:justify-start flex-1 items-center space-x-4 sm:space-x-10 2xl:space-x-14">
            <Logo />
            <Navigation className="hidden lg:flex" />
          </div>

          <div className="flex-1 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
            <div className="hidden items-center lg:flex">
              <div className="px-1"></div>
              <Button sizeClass="py-3 px-4 sm:px-6" href="https://secure.finvue.co.uk" pattern="white">
                Login
              </Button>
              <Button sizeClass="py-3 px-4 sm:px-6" href="/sign-up" pattern="primary">
                Sign up
              </Button>
            </div>
            <div className="flex items-center lg:hidden">
              <Button sizeClass="py-3 px-4 sm:px-6" href="https://secure.finvue.co.uk" pattern="white">
                Login
              </Button>
              <Button sizeClass="py-3 px-4 sm:px-6" href="/sign-up" pattern="primary">
                Sign up
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteNavbar;
