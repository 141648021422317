import Logo from "components/Logo/Logo";
import MenuBar from "components/MenuBar/MenuBar";
import Navigation from "components/Navigation/Navigation";
import React, { FC, useEffect, useState } from "react";
import Button from "../Button/Button";

export interface WhiteNavbarProps {
  isTransparent?: boolean; // Allow overriding transparency
}

const WhiteNavbar: FC<WhiteNavbarProps> = ({ isTransparent: initialTransparent = true }) => {
  const [isTransparent, setIsTransparent] = useState(initialTransparent);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsTransparent(scrollY === 0); // Transparent only at the top
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`nc-MainNav1 relative z-40 transition-all duration-300 ${isTransparent ? "absolute bg-transparent -mb-20" : "bg-slate-900"}`}>
      <div className="container">
        <div className="h-20 py-5 flex justify-between items-center">
          <div className="flex justify-center lg:justify-start flex-0 items-center space-x-4 sm:space-x-10 2xl:space-x-14">
            <Logo />
            {/* Pass color dynamically to Navigation */}
            <Navigation className="hidden lg:flex" color="light" />
          </div>

          <div className="flex-1 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
            <div className="hidden items-center lg:flex gap-2">
              <div className="px-1"></div>
              <Button sizeClass="py-3 px-4 sm:px-6" href="https://secure.finvue.co.uk" pattern="secondary">
                Login
              </Button>
              <Button sizeClass="py-3 px-4 sm:px-6" href="/sign-up" pattern="primary">
                Sign up
              </Button>
            </div>
            <div className="flex items-center lg:hidden gap-2">
              {/* <Button sizeClass="py-3 px-4 sm:px-6" href="##" pattern="white">
                Login
              </Button> */}
              <Button sizeClass="py-3 px-4 sm:px-6" href="/sign-up" pattern="primary">
                Sign up
              </Button>
            </div>
          </div>
          <div className="flex items-center lg:hidden flex-3">
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhiteNavbar;
